'use client';

/**
 * A mapping of commonly used icons
 * This approach allows us to gradually migrate from the global import pattern
 * to individual imports without breaking existing functionality
 */
export const COMMON_ICONS = {
  // Navigation icons
  Menu: () => import('@mui/icons-material/Menu').then(mod => mod.default),
  Search: () => import('@mui/icons-material/Search').then(mod => mod.default),
  FilterList: () => import('@mui/icons-material/FilterList').then(mod => mod.default),
  ExpandMore: () => import('@mui/icons-material/ExpandMore').then(mod => mod.default),
  ExpandLess: () => import('@mui/icons-material/ExpandLess').then(mod => mod.default),
  LocationOn: () => import('@mui/icons-material/LocationOn').then(mod => mod.default),
  
  // Action icons
  Add: () => import('@mui/icons-material/Add').then(mod => mod.default),
  Edit: () => import('@mui/icons-material/Edit').then(mod => mod.default),
  Delete: () => import('@mui/icons-material/Delete').then(mod => mod.default),
  Close: () => import('@mui/icons-material/Close').then(mod => mod.default),
  Visibility: () => import('@mui/icons-material/Visibility').then(mod => mod.default),
  
  // Social icons
  People: () => import('@mui/icons-material/People').then(mod => mod.default),
  AccountCircleOutlined: () => import('@mui/icons-material/AccountCircleOutlined').then(mod => mod.default),
  
  // Event icons
  Celebration: () => import('@mui/icons-material/Celebration').then(mod => mod.default),
  MusicNote: () => import('@mui/icons-material/MusicNote').then(mod => mod.default),
  LocalBar: () => import('@mui/icons-material/LocalBar').then(mod => mod.default),
  Restaurant: () => import('@mui/icons-material/Restaurant').then(mod => mod.default),
  
  // Feedback icons
  Error: () => import('@mui/icons-material/Error').then(mod => mod.default),
  CheckCircle: () => import('@mui/icons-material/CheckCircle').then(mod => mod.default),
  
  // Venue & Activity Features
  SportsVolleyballOutlined: () => import('@mui/icons-material/SportsVolleyballOutlined').then(mod => mod.default), // Beer Pong
  SportsBarOutlined: () => import('@mui/icons-material/SportsBarOutlined').then(mod => mod.default), // Craft Beer
  ParkOutlined: () => import('@mui/icons-material/ParkOutlined').then(mod => mod.default), // Outdoor Area
  SpeakerGroupOutlined: () => import('@mui/icons-material/SpeakerGroupOutlined').then(mod => mod.default), // Audio Setup
  SportsBar: () => import('@mui/icons-material/SportsBar').then(mod => mod.default), // Bar
  Filter8TwoTone: () => import('@mui/icons-material/Filter8TwoTone').then(mod => mod.default), // Billiards
  Business: () => import('@mui/icons-material/Business').then(mod => mod.default), // Brooklyn, East Village, etc.
  AudiotrackTwoTone: () => import('@mui/icons-material/AudiotrackTwoTone').then(mod => mod.default), // Casual
  CorporateFare: () => import('@mui/icons-material/CorporateFare').then(mod => mod.default), // Corporate
  Nightlife: () => import('@mui/icons-material/Nightlife').then(mod => mod.default), // Dance Floor
  Adjust: () => import('@mui/icons-material/Adjust').then(mod => mod.default), // Darts
  TipsAndUpdatesTwoTone: () => import('@mui/icons-material/TipsAndUpdatesTwoTone').then(mod => mod.default), // Disco Ball
  AutoAwesome: () => import('@mui/icons-material/AutoAwesome').then(mod => mod.default), // Fancy, Wedding
  Fastfood: () => import('@mui/icons-material/Fastfood').then(mod => mod.default), // Food
  FoodBankRounded: () => import('@mui/icons-material/FoodBankRounded').then(mod => mod.default), // Fully Equipped Kitchen
  SportsEsportsOutlined: () => import('@mui/icons-material/SportsEsportsOutlined').then(mod => mod.default), // Games
  LocalBarOutlined: () => import('@mui/icons-material/LocalBarOutlined').then(mod => mod.default), // Great Cocktails
  LocationCityOutlined: () => import('@mui/icons-material/LocationCityOutlined').then(mod => mod.default), // Greenwich Village, Lower Manhattan
  CakeRounded: () => import('@mui/icons-material/CakeRounded').then(mod => mod.default), // Intimate Gathering
  Flare: () => import('@mui/icons-material/Flare').then(mod => mod.default), // Light Show
  LightTwoTone: () => import('@mui/icons-material/LightTwoTone').then(mod => mod.default), // Lighting
  Piano: () => import('@mui/icons-material/Piano').then(mod => mod.default), // Live Music
  LiveTvSharp: () => import('@mui/icons-material/LiveTvSharp').then(mod => mod.default), // Live Sports
  HomeWorkOutlined: () => import('@mui/icons-material/HomeWorkOutlined').then(mod => mod.default), // Locally Brewed
  Chair: () => import('@mui/icons-material/Chair').then(mod => mod.default), // Lounge
  LocationCitySharp: () => import('@mui/icons-material/LocationCitySharp').then(mod => mod.default), // Lower East Side, Soho
  WineBar: () => import('@mui/icons-material/WineBar').then(mod => mod.default), // Natural Wine
  MoneyOffTwoTone: () => import('@mui/icons-material/MoneyOffTwoTone').then(mod => mod.default), // No Minimum Spend
  AccessTimeFilledRounded: () => import('@mui/icons-material/AccessTimeFilledRounded').then(mod => mod.default), // Open Late
  SportsTennis: () => import('@mui/icons-material/SportsTennis').then(mod => mod.default), // Ping Pong
  Pool: () => import('@mui/icons-material/Pool').then(mod => mod.default), // Pool
  SquareFoot: () => import('@mui/icons-material/SquareFoot').then(mod => mod.default), // Raw Space
  LocationCity: () => import('@mui/icons-material/LocationCity').then(mod => mod.default), // Rooftop Views
  RestaurantMenu: () => import('@mui/icons-material/RestaurantMenu').then(mod => mod.default), // Seasonal Menu
  ArrowDropDownCircleSharp: () => import('@mui/icons-material/ArrowDropDownCircleSharp').then(mod => mod.default), // Shuffle Board
  EnergySavingsLeafRounded: () => import('@mui/icons-material/EnergySavingsLeafRounded').then(mod => mod.default), // Sustainable
  WineBarTwoTone: () => import('@mui/icons-material/WineBarTwoTone').then(mod => mod.default), // Well Appointed Bar
};

// This type helps with TypeScript integration
export type IconNames = keyof typeof COMMON_ICONS;