import { db } from './app';
import {
  collection,
  where,
  getDocs,
  collectionGroup,
  addDoc,
  query,
  doc,
  updateDoc,
  deleteDoc,
  writeBatch,
  getDoc,
  orderBy,
  limit,
  setDoc,
  QueryConstraint,
  DocumentSnapshot,
  startAfter,
  or,
  documentId,
  runTransaction,
  QuerySnapshot,
  DocumentData,
} from 'firebase/firestore';
import { Venue, Review, DayOfWeek, DayAvailability, Section, PricingRate, VenueBookedTimeSlot } from '../../../../shared/models';
import { uploadFilesToStorageAndStoreURLs } from './util';

/**
 * Fetches a venue by its ID, along with its availabilities within an optional date range.
 * @param venueId The ID of the venue to fetch.
 * @param startDate The start date for the availability range (optional).
 * @param endDate The end date for the availability range (optional).
 * @param bookedOnly Whether to only fetch availabilities that are booked (optional).
 * @returns The venue object with its availabilities.
 */
export async function getVenueById(
  venueId: string,
): Promise<Venue | null> {
  const venueRef = doc(db, 'venues', venueId);
  const venueSnapshot = await getDoc(venueRef);

  if (!venueSnapshot.exists()) {
    console.log('No venue found with the given ID.');
    return null;
  }

  const venueData = venueSnapshot.data() as Venue;

  // Convert Firestore Timestamps to JavaScript Date objects
  if (venueData.Availability) {
    venueData.Availability = convertFirestoreTimestamps(venueData.Availability);
  }

  // Convert PricingRatesPerSectionOverride timestamps
  if (venueData.PricingRatesPerSectionOverride) {
    Object.keys(venueData.PricingRatesPerSectionOverride).forEach((sectionId) => {
      venueData.PricingRatesPerSectionOverride[sectionId] = venueData.PricingRatesPerSectionOverride[sectionId].map(override => ({
        ...override,
        StartTime: 'toDate' in override.StartTime ? (override.StartTime as any).toDate() : override.StartTime,
        EndTime: 'toDate' in override.EndTime ? (override.EndTime as any).toDate() : override.EndTime
      }));
    });
  }

  const venue = {
    ID: venueId,
    ...venueData
  };

  return venue;
}

import { getVenueBookedTimeSlotsForMultipleVenues } from './bookings';
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { revalidateCache, revalidatePathAction } from '../../actions/revalidate';

interface FetchVenuesOptions {
  startDate: Date;
  endDate?: Date;
  priceRange?: [number, number];
  Booked?: boolean;
  maxPartySize?: number;
  attributes?: string[];
}
export async function FetchVenuesWithAvailability(
  options: FetchVenuesOptions
): Promise<{ venues: Venue[], venueBookedTimeSlots: Record<string, Record<string, VenueBookedTimeSlot[]>> }> {
  const venuesRef = collection(db, 'venues');
  const venueQueryConstraints: QueryConstraint[] = [];

  venueQueryConstraints.push(where('Enabled', '==', true));
  if (options.attributes && options.attributes.length > 0) {
    const locationTypes = [
      'greenwich village', 'midtown', 'east village', 'queens', 'soho',
      'williamsburg', 'brooklyn', 'lower east side', 'lower manhattan'
    ];

    const locationAttributes = options.attributes.filter(attr => locationTypes.includes(attr));
    const otherAttributes = options.attributes.filter(attr => !locationTypes.includes(attr));

    // Handle location attributes with OR
    if (locationAttributes.length > 0) {
      // If there's only one location attribute, add it directly to constraints
      if (locationAttributes.length === 1) {
        venueQueryConstraints.push(
          where(`Attributes.${locationAttributes[0]}`, '==', true)
        );
      }
    }

    // Handle other attributes with AND
    otherAttributes.forEach(attr => {
      venueQueryConstraints.push(where(`Attributes.${attr}`, '==', true));
    });
  }

  const venueQuery = query(venuesRef, ...venueQueryConstraints);
  let venueSnapshot = await getDocs(venueQuery);

  // Handle multiple location attributes by merging results
  if (options.attributes && options.attributes.length > 0) {
    const locationTypes = [
      'greenwich village', 'midtown', 'east village', 'queens', 'soho',
      'williamsburg', 'brooklyn', 'lower east side', 'lower manhattan'
    ];
    const locationAttributes = options.attributes.filter(attr => locationTypes.includes(attr));

    if (locationAttributes.length > 1) {
      const locationResults = new Set<string>();

      // Get results for each location query
      for (const attr of locationAttributes) {
        const locationQuery = query(
          venuesRef,
          where('Enabled', '==', true),
          where(`Attributes.${attr}`, '==', true)
        );
        const locationSnapshot = await getDocs(locationQuery);
        locationSnapshot.docs.forEach(doc => locationResults.add(doc.id));
      }

      // Filter main results to include venues that matched any location
      // Handle Firestore's "in" clause 30-item limit by processing in chunks
      const filteredDocIds = venueSnapshot.docs
        .filter(doc => locationResults.has(doc.id))
        .map(doc => doc.id);

      let allFilteredDocs: QuerySnapshot<DocumentData>[] = [];

      // Process IDs in chunks of 30
      for (let i = 0; i < filteredDocIds.length; i += 30) {
        const chunk = filteredDocIds.slice(i, i + 30);
        const chunkQuery = query(
          venuesRef,
          where(documentId(), 'in', chunk)
        );
        const chunkSnapshot = await getDocs(chunkQuery);
        allFilteredDocs.push(chunkSnapshot);
      }

      // Combine all chunks into a single snapshot
      venueSnapshot = {
        docs: allFilteredDocs.flatMap(snapshot => snapshot.docs),
        size: allFilteredDocs.reduce((total, snapshot) => total + snapshot.size, 0),
        empty: allFilteredDocs.every(snapshot => snapshot.empty),
        forEach: (callback) => {
          allFilteredDocs.forEach(snapshot => snapshot.forEach(callback));
        }
      } as QuerySnapshot<DocumentData>;
    }
  }

  const venues: Venue[] = [];
  const venueBookedTimeSlots: Record<string, Record<string, VenueBookedTimeSlot[]>> = {};

  const allVenues = await Promise.all(
    venueSnapshot.docs.map(async (doc) => {
      const venueId = doc.id;
      return await getVenueById(venueId);
    })
  );

  const filteredVenues = allVenues.filter(venue => venue !== null);
  const venueIds = filteredVenues.map(venue => venue.ID).filter(Boolean) as string[];
  const allBookedTimeSlots = await getVenueBookedTimeSlotsForMultipleVenues(venueIds, options.startDate, options.endDate);

  for (const venue of filteredVenues) {
    if (!venue.ID) continue;
    venueBookedTimeSlots[venue.ID] = allBookedTimeSlots[venue.ID] || {};
  }

  for (const venue of filteredVenues) {
    if (!venue.Availability) {
      continue;
    }

    const entries = Object.entries(venue.Availability);
    const availableDays = entries.filter(([day, availability]) => {
      if (!availability.IsOpen) return false;

      const dayIndex = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].indexOf(day);
      const dayDate = new Date(options.startDate);
      dayDate.setDate(dayDate.getDate() + dayIndex);
      const dateString = dayDate.toDateString();

      if (!venueBookedTimeSlots[venue.ID][dateString]) return true;

      // Handle both single time range and multiple time ranges
      const timeRanges = availability.Times || [{
        StartTime: availability.StartTime,
        EndTime: availability.EndTime
      }];

      // Check if any time range is available
      return timeRanges.some(timeRange => {
        const dayStart = new Date(dayDate);
        dayStart.setHours(timeRange.StartTime.getHours(), timeRange.StartTime.getMinutes(), 0, 0);
        const dayEnd = new Date(dayDate);
        dayEnd.setHours(timeRange.EndTime.getHours(), timeRange.EndTime.getMinutes(), 59, 999);

        return !venueBookedTimeSlots[venue.ID][dateString].some(slot =>
          slot.StartTime <= dayStart && slot.EndTime >= dayEnd
        );
      });
    });

    if (availableDays.length === 0) continue;

    if (options.maxPartySize && venue.Sections) {
      const hasEnoughCapacity = Object.values(venue.Sections).some(section => section.Capacity >= options.maxPartySize!);
      if (!hasEnoughCapacity) continue;
    }

    if (options.priceRange && venue.PricingRatesPerSection) {
      const [minPrice, maxPrice] = options.priceRange;
      const isInPriceRange = Object.values(venue.PricingRatesPerSection).some(rate =>
        rate.RatePerHour >= minPrice && rate.RatePerHour <= maxPrice
      );
      if (!isInPriceRange) continue;
    }

    venues.push(venue);
  }

  return { venues, venueBookedTimeSlots };
}

/**
 * Creates a new Venue and adds it to the database.
 * @param venueData Partial data for the venue. Must include Name, Address, and Description.
 * @returns The newly created Venue object with an ID or an error message if the venue already exists.
 */
export async function createVenue(venueData: Partial<Venue>): Promise<Venue | { error: string }> {
  const venuesRef = collection(db, 'venues');

  // Parse the Position field if it exists and is in the correct format
  let position = { lat: 0, lng: 0 };
  if (venueData.Position && typeof venueData.Position === 'string') {
    let positionString = venueData.Position as string;
    const [lat, lng] = positionString.split(',').map(Number);
    position = { lat, lng };
  }

  if (venueData.Attributes && typeof venueData.Attributes === 'string') {
    let attributesString = venueData.Attributes as string;
    venueData.Attributes = attributesString.split(',').reduce((acc, attr) => {
      acc[attr.trim()] = true;
      return acc;
    }, {} as { [key: string]: boolean });
  }

  // First create a document reference to get the ID
  const newVenueRef = doc(venuesRef);
  const venueId = newVenueRef.id;

  const newVenue: Venue = {
    ID: venueId,
    Name: venueData.Name || '',
    Address: venueData.Address || '',
    Subtitle: venueData.Subtitle || '',
    Description: venueData.Description || '',
    Number: venueData.Number || '',
    Availability: venueData.Availability || {
      Monday: { StartTime: new Date(), EndTime: new Date(), IsOpen: false, Times: [] },
      Tuesday: { StartTime: new Date(), EndTime: new Date(), IsOpen: false, Times: [] },
      Wednesday: { StartTime: new Date(), EndTime: new Date(), IsOpen: false, Times: [] },
      Thursday: { StartTime: new Date(), EndTime: new Date(), IsOpen: false, Times: [] },
      Friday: { StartTime: new Date(), EndTime: new Date(), IsOpen: false, Times: [] },
      Saturday: { StartTime: new Date(), EndTime: new Date(), IsOpen: false, Times: [] },
      Sunday: { StartTime: new Date(), EndTime: new Date(), IsOpen: false, Times: [] },
    },
    PhotoUrls: venueData.PhotoUrls || [],
    Rating: venueData.Rating || 0,
    Position: venueData.Position || { lat: 0, lng: 0 },
    Sections: venueData.Sections || {},
    PricingRatesPerSection: venueData.PricingRatesPerSection || {},
    Attributes: venueData.Attributes || {},
    Enabled: venueData.Enabled || false,
    AdminEmailsSeparatedByComma: venueData.AdminEmailsSeparatedByComma || '',
    CheckoutPageDescription: venueData.CheckoutPageDescription || '',
    PricingRatesPerSectionPerDayOfWeek: venueData.PricingRatesPerSectionPerDayOfWeek || {},
    AdditionalFees: venueData.AdditionalFees || [],
    BookingPolicy: venueData.BookingPolicy || '',
    ThingsToKnow: venueData.ThingsToKnow || '',
    PricingRatesPerSectionOverride: venueData.PricingRatesPerSectionOverride || {},
    HostFirstNameLastInitial: venueData.HostFirstNameLastInitial || '',
    HasConnectedACalendarOnce: false,
    LastModified: new Date(),
    ViewableForInquiry: false,
    SimilarVenueIds: [],
    FileUrls: [],
    CreatorEmail: venueData.CreatorEmail || '',
  };

  // Now actually set the document with the data
  await setDoc(newVenueRef, {
    Name: venueData.Name,
    Address: venueData.Address,
    Description: venueData.Description,
    Number: venueData.Number || '',
    Availability: {},
    Subtitle: venueData.Subtitle || '',
    Rating: venueData.Rating || 0,
    Position: position,
    Attributes: venueData.Attributes || [],
    Enabled: venueData.Enabled || false,
    LastModified: new Date(),
    CreatorEmail: venueData.CreatorEmail || '',
  });

  console.log('New venue created with ID:', venueId);
  return newVenue;
}

/**
 * Fetches venues and their availabilities from the database with pagination.
 * @param pageSize The number of venues to fetch per page.
 * @param startAfter The ID of the last venue from the previous page, or null for the first page.
 * @param onlyEnabled Whether to fetch only enabled venues.
 * @returns An object containing an array of Venue objects and the last document snapshot.
 */
export async function FetchAllVenues(pageSize: number = 10, startAfterDocId: string | null = null, onlyEnabled: boolean = false): Promise<{ venues: Venue[], lastVisible: any }> {
  const venuesRef = collection(db, 'venues');
  let venueQuery = query(venuesRef, orderBy('Name'), limit(pageSize));

  if (onlyEnabled) {
    venueQuery = query(venueQuery, where('Enabled', '==', true));
  }

  if (startAfterDocId) {
    const lastDoc = await getDoc(doc(db, 'venues', startAfterDocId));
    venueQuery = query(venueQuery,
      startAfter(lastDoc),
      limit(pageSize)
    );
  }

  const snapshot = await getDocs(venueQuery);
  const venues: Venue[] = [];

  snapshot.forEach((doc) => {
    const data = doc.data() as Venue;
    data.ID = doc.id;

    // Convert availability times with .toDate()
    if (data.Availability) {
      data.Availability = convertFirestoreTimestamps(data.Availability);
    }

    data.LastModified = data.LastModified instanceof Date
      ? data.LastModified
      : (data.LastModified && !(data.LastModified as any instanceof Date))
        ? new Date(data.LastModified)
        : new Date();

    venues.push(data);
  });
  const lastVisible = snapshot.docs[snapshot.docs.length - 1];

  // Sort venues photos
  venues.forEach(venue => sortVenuePhotos(venue));

  return { venues, lastVisible };
}

// Helper function to sort photo URLs
const sortPhotoUrls = (urls: string[]): string[] => {
  if (!urls || urls.length === 0) return [];

  // Group URLs by their base filename (without size suffix)
  const urlGroups = new Map<string, string[]>();

  urls.forEach(url => {
    const baseUrl = url.replace(/_[0-9]+x[0-9]+/g, '');
    if (!urlGroups.has(baseUrl)) {
      urlGroups.set(baseUrl, []);
    }
    urlGroups.get(baseUrl)?.push(url);
  });

  // Sort groups based on 400x400 version order
  const sortedBaseUrls = Array.from(urlGroups.keys()).sort((a, b) => {
    const aIndex = urls.findIndex(url => url.includes(a + '_400x400'));
    const bIndex = urls.findIndex(url => url.includes(b + '_400x400'));
    if (aIndex === -1) return 1;
    if (bIndex === -1) return -1;
    return aIndex - bIndex;
  });

  // Flatten groups back into single array
  return sortedBaseUrls.flatMap(baseUrl => urlGroups.get(baseUrl) || []);
};

// Reorder venue photos and section images to keep all versions of each photo together
export const sortVenuePhotos = (venue: Venue): Venue => {
  // Handle venue photos
  if (venue.PhotoUrls && venue.PhotoUrls.length > 0) {
    venue.PhotoUrls = sortPhotoUrls(venue.PhotoUrls);
  }

  // Handle section images
  if (venue.Sections) {
    Object.values(venue.Sections).forEach(section => {
      if (section.Images && section.Images.length > 0) {
        section.Images = sortPhotoUrls(section.Images);
      }
    });
  }

  return venue;
};

/**
 * Updates a venue's details in the database.
 * @param venue The venue object with updated details. Can be partial - only provided fields will be updated.
 * @returns The updated venue object.
 */
export async function updateVenue(venue: Partial<Venue> & { ID: string }): Promise<Venue> {
  const venueRef = doc(db, 'venues', venue.ID);

  // Process Position if provided
  if (venue.Position && typeof venue.Position === 'string') {
    let positionString = venue.Position as string;
    const [lat, lng] = positionString.split(',').map(Number);
    venue.Position = { lat, lng };
  }

  // Process Attributes if provided
  if (venue.Attributes && typeof venue.Attributes === 'string') {
    let attributesString = venue.Attributes as string;
    venue.Attributes = attributesString.split(',').reduce((acc, attr) => {
      acc[attr.trim()] = true;
      return acc;
    }, {} as { [key: string]: boolean });
  }

  // Process Availability if provided
  if (venue.Availability) {
    venue.Availability = convertFirestoreTimestamps(venue.Availability);
  }

  // Create update object with only provided fields
  const updateData = {
    ...venue,
    LastModified: new Date()
  };


  await updateDoc(venueRef, updateData as any);

  revalidateCache('venues');
  revalidatePathAction('/');

  // Fetch and return the complete updated venue
  const updatedDoc = await getDoc(venueRef);
  return { ID: venue.ID, ...updatedDoc.data() } as Venue;
}

/**
 * Fetches all reviews for a given venue.
 * @param venueId The ID of the venue.
 * @returns An object containing an array of Review objects and the total count of reviews.
 */
export async function getReviews(
  venueId: string
): Promise<{ reviews: Review[]; count: number }> {
  const reviewsRef = collection(db, 'venues', venueId, 'reviews');
  const snapshot = await getDocs(reviewsRef);
  const reviews: Review[] = [];

  snapshot.forEach((doc) => {
    const data = doc.data();
    reviews.push({
      ID: doc.id,
      VenueID: venueId,
      ReviewerName: data.ReviewerName,
      Rating: data.Rating,
      Comment: data.Comment,
      CreatedAt: data.CreatedAt,
    });
  });

  return { reviews, count: reviews.length };
}

/**
 * Adds a new review for a given venue.
 * @param venueId The ID of the venue.
 * @param review The review object to add.
 * @returns The added review object with its ID.
 */
export async function addReview(
  venueId: string,
  review: Omit<Review, 'ID' | 'VenueID'>
): Promise<Review> {
  const reviewsRef = collection(db, 'venues', venueId, 'reviews');
  const docRef = await addDoc(reviewsRef, review);
  const addedReview: Review = {
    ID: docRef.id,
    VenueID: venueId,
    ...review,
  };
  revalidateCache('reviews');
  return addedReview;
}

/**
 * Edits an existing review for a given venue.
 * @param venueId The ID of the venue.
 * @param reviewId The ID of the review to edit.
 * @param updatedReview The updated review data.
 * @returns A boolean indicating if the edit was successful.
 */
export async function editReview(
  venueId: string,
  reviewId: string,
  updatedReview: Partial<Omit<Review, 'ID' | 'VenueID'>>
): Promise<boolean> {
  try {
    const reviewRef = doc(db, 'venues', venueId, 'reviews', reviewId);
    await updateDoc(reviewRef, updatedReview);
    revalidateCache('reviews');
    return true;
  } catch (error) {
    console.error('Failed to edit review:', error);
    return false;
  }
}

/**
 * Deletes a specific review from a venue.
 * @param venueId The ID of the venue.
 * @param reviewId The ID of the review to delete.
 * @returns A boolean indicating if the deletion was successful.
 */
export async function deleteReview(
  venueId: string,
  reviewId: string
): Promise<boolean> {
  try {
    const reviewRef = doc(db, 'venues', venueId, 'reviews', reviewId);
    await deleteDoc(reviewRef);
    revalidateCache('reviews');
    return true;
  } catch (error) {
    console.error('Failed to delete review:', error);
    return false;
  }
}

/**
 * Deletes a specific picture from a venue.
 * @param venueId The ID of the venue.
 * @param photoUrl The URL of the photo to delete.
 * @returns A boolean indicating if the deletion was successful.
 */
export async function deleteVenuePicture(venueId: string, photoUrl: string): Promise<boolean> {
  try {
    const venueRef = doc(db, 'venues', venueId);

    return await runTransaction(db, async (transaction) => {
      const venueDoc = await transaction.get(venueRef);

      if (!venueDoc.exists()) {
        console.error('Venue not found');
        return false;
      }

      const venueData = venueDoc.data() as Venue;

      // Remove the photo URL from the PhotoUrls array
      const updatedPhotoUrls = venueData.PhotoUrls?.filter(url => url !== photoUrl) || [];

      // Update the venue document in transaction
      transaction.update(venueRef, { PhotoUrls: updatedPhotoUrls });

      try {
        // Delete the file from Firebase Storage
        const storage = getStorage();

        // Create the full storage path from the relative path
        // The relative path is like 'venue/filename.jpg'
        // The storage path should be 'venues/venueId/filename.jpg'
        const fullStoragePath = photoUrl.startsWith('venue/')
          ? `venues/${venueId}/${photoUrl.substring(6)}` // Remove 'venue/' prefix and add the full path
          : photoUrl; // Legacy format - use as-is

        const storageRef = ref(storage, fullStoragePath);
        await deleteObject(storageRef);
      } catch (storageError) {
        // Log but continue if storage delete fails
        console.log('Storage delete failed, continuing:', storageError);
      }

      revalidateCache('venues');

      return true;
    });

  } catch (error) {
    console.error('Failed to delete venue picture:', error);
    return false;
  }
}

/**
 * Deletes a specific picture from a venue section.
 * @param venueId The ID of the venue.
 * @param sectionId The ID of the section.
 * @param photoUrl The URL of the photo to delete.
 * @returns A boolean indicating if the deletion was successful.
 */
export async function deleteVenueSectionPicture(venueId: string, sectionId: string, photoUrl: string): Promise<boolean> {
  try {
    const venueRef = doc(db, 'venues', venueId);

    return await runTransaction(db, async (transaction) => {
      const venueDoc = await transaction.get(venueRef);

      if (!venueDoc.exists()) {
        console.error('Venue not found');
        return false;
      }

      const venueData = venueDoc.data() as Venue;

      // Remove the photo URL from the section's Images array
      if (venueData.Sections && venueData.Sections[sectionId]) {
        venueData.Sections[sectionId].Images = venueData.Sections[sectionId].Images?.filter(url => url !== photoUrl) || [];

        // Update the venue document in transaction
        transaction.update(venueRef, { [`Sections.${sectionId}.Images`]: venueData.Sections[sectionId].Images });

        try {
          // Delete the file from Firebase Storage
          const storage = getStorage();

          // Create the full storage path from the relative path
          // The relative path is like 'venues/sectionId/filename.jpg'
          // The storage path should be 'venues/venueId/sections/sectionId/filename.jpg'
          const fullStoragePath = photoUrl.startsWith(`venues/${sectionId}/`)
            ? `venues/${venueId}/sections/${photoUrl.substring(7)}` // Convert from 'venues/sectionId/filename.jpg' to 'venues/venueId/sections/sectionId/filename.jpg'
            : photoUrl; // Legacy format - use as-is

          const storageRef = ref(storage, fullStoragePath);
          await deleteObject(storageRef);
        } catch (storageError) {
          // Log but continue if storage delete fails
          console.log('Storage delete failed, continuing:', storageError);
        }

        revalidateCache('venues');
        return true;
      } else {
        console.error('Section not found in venue');
        return false;
      }
    });

  } catch (error) {
    console.error('Failed to delete venue section picture:', error);
    return false;
  }
}


/**
 * Deletes a venue and all its availabilities and reviews.
 * @param venueId The ID of the venue to delete.
 * @returns A boolean indicating if the deletion was successful.
 */
export async function deleteVenue(venueId: string): Promise<boolean> {
  try {
    const batch = writeBatch(db);

    // Delete all availabilities associated with the venue
    const availabilitiesRef = collection(db, 'venues', venueId, 'availabilities');
    const availabilitiesSnapshot = await getDocs(availabilitiesRef);
    availabilitiesSnapshot.docs.forEach((doc) => {
      batch.delete(doc.ref);
    });

    // Delete all reviews associated with the venue
    const reviewsRef = collection(db, 'venues', venueId, 'reviews');
    const reviewsSnapshot = await getDocs(reviewsRef);
    reviewsSnapshot.docs.forEach((doc) => {
      batch.delete(doc.ref);
    });

    // Delete the venue itself
    const venueRef = doc(db, 'venues', venueId);
    batch.delete(venueRef);

    // Commit the batch
    await batch.commit();
    revalidateCache('venues');
    return true;
  } catch (error) {
    console.error('Failed to delete venue, its availabilities, and reviews:', error);
    return false;
  }
}

export async function uploadVenuePictures(
  venueId: string,
  files: File[],
  onProgress?: (progress: number) => void
): Promise<void> {
  await uploadFilesToStorageAndStoreURLs({
    files,
    venueId,
    onProgress,
  });
}

export async function createVenueSectionPictures(
  venueId: string,
  sectionId: string,
  files: File[],
  onProgress?: (progress: number) => void
): Promise<void> {
  await uploadFilesToStorageAndStoreURLs({
    files,
    venueId,
    sectionId,
    onProgress,
  });
}

export async function uploadVenueFiles(
  venueId: string,
  files: File[],
  onProgress?: (progress: number) => void
): Promise<string[]> {
  const storage = getStorage();
  const fileUrls: string[] = [];
  const totalFiles = files.length;
  let uploadedFiles = 0;

  // Upload all files first
  for (const file of files) {
    const fileExtension = file.name.split('.').pop()?.replace(/[^a-zA-Z0-9]/g, '') || 'txt';
    const uuid = crypto.randomUUID().substring(0, 6);
    const newFileName = `${uuid}.${fileExtension.toLowerCase()}`;
    const fileRef = ref(storage, `venues/${venueId}/files/${newFileName}`);

    await uploadBytes(fileRef, file);
    const downloadUrl = await getDownloadURL(fileRef);
    fileUrls.push(downloadUrl);

    uploadedFiles++;
    if (onProgress) {
      onProgress((uploadedFiles / totalFiles) * 100);
    }
  }

  // Update venue document with new file URLs in a transaction
  const venueRef = doc(db, 'venues', venueId);
  await runTransaction(db, async (transaction) => {
    const venueDoc = await transaction.get(venueRef);
    if (!venueDoc.exists()) {
      throw new Error('Venue does not exist');
    }

    const venueData = venueDoc.data() as Venue;
    const existingUrls = venueData.FileUrls || [];

    const newFileUrls = files.map((file, index) => ({
      label: file.name,
      url: fileUrls[index]
    }));

    transaction.update(venueRef, {
      FileUrls: [...existingUrls, ...newFileUrls],
      LastModified: new Date()
    });
  });

  revalidateCache('venues');
  return fileUrls;
}



interface AllVenueMetadata {
  possibleAttributes: string[];
}

export const fetchAllVenueMetadata = async (): Promise<AllVenueMetadata> => {
  const metadataRef = doc(db, 'metadata', 'AllVenueMetadata');
  const metadataSnap = await getDoc(metadataRef);

  if (metadataSnap.exists()) {
    return metadataSnap.data() as AllVenueMetadata;
  } else {
    // If the document doesn't exist, create it with an empty array
    const initialMetadata: AllVenueMetadata = { possibleAttributes: [] };
    await setDoc(metadataRef, initialMetadata);
    return initialMetadata;
  }
};

export const updateAllVenueMetadata = async (): Promise<void> => {
  // Run both queries in parallel
  const [venuesSnapshot, metadataSnap] = await Promise.all([
    getDocs(query(collection(db, 'venues'), where('Enabled', '==', true))),
    getDoc(doc(db, 'metadata', 'AllVenueMetadata'))
  ]);

  // Process venue data to get attributes
  const allAttributes = new Set<string>();
  venuesSnapshot.forEach((doc) => {
    const venueData = doc.data() as Venue;
    if (venueData.Attributes && typeof venueData.Attributes === 'object') {
      Object.keys(venueData.Attributes).forEach((attr) => {
        if (venueData.Attributes[attr] === true) {
          allAttributes.add(attr.toLowerCase());
        }
      });
    }
  });

  const updatedAttributes = Array.from(allAttributes);
  const metadataRef = doc(db, 'metadata', 'AllVenueMetadata');

  // Single write operation using setDoc with merge
  await setDoc(metadataRef, {
    possibleAttributes: updatedAttributes
  }, { merge: true });
};

export const convertFirestoreTimestamps = (availability: Record<DayOfWeek, DayAvailability>) => {
  if (!availability) return availability;

  Object.keys(availability).forEach((day) => {
    const dayAvailability = availability[day as DayOfWeek];
    if (!dayAvailability) return;

    // Handle legacy StartTime/EndTime
    if (dayAvailability.StartTime && 'toDate' in dayAvailability.StartTime) {
      dayAvailability.StartTime = (dayAvailability.StartTime as any).toDate();
    }
    if (dayAvailability.EndTime && 'toDate' in dayAvailability.EndTime) {
      dayAvailability.EndTime = (dayAvailability.EndTime as any).toDate();
    }

    // Populate Times array from legacy fields if it doesn't exist
    if (!dayAvailability.Times && dayAvailability.StartTime && dayAvailability.EndTime) {
      dayAvailability.Times = [{
        StartTime: dayAvailability.StartTime,
        EndTime: dayAvailability.EndTime
      }];
    }

    // Convert Times array timestamps
    if (dayAvailability.Times) {
      dayAvailability.Times = dayAvailability.Times.map((time: any) => ({
        StartTime: 'toDate' in time.StartTime ? time.StartTime.toDate() : time.StartTime,
        EndTime: 'toDate' in time.EndTime ? time.EndTime.toDate() : time.EndTime
      }));
    }

    // Convert Slots timestamps
    if (dayAvailability.Slots) {
      dayAvailability.Slots = dayAvailability.Slots.map((slot: any) => ({
        ...slot,
        StartTime: 'toDate' in slot.StartTime ? slot.StartTime.toDate() : slot.StartTime,
        EndTime: 'toDate' in slot.EndTime ? slot.EndTime.toDate() : slot.EndTime,
      }));
    }
  });

  return availability;
};
