import { db } from './app';
import {
  collection,
  query,
  where,
  getDocs,
  collectionGroup,
  addDoc,
  doc,
  updateDoc,
  deleteDoc,
  writeBatch,
  orderBy,
  getDoc,
  setDoc,
  limit,
  startAfter,
  runTransaction,
} from 'firebase/firestore';
import { Booking, Venue, VenueBookedTimeSlot, VenueBookedTimeSlotGuess } from '../../../../shared/models';
import { getVenueById } from './venues';

export async function getBookingById(bookingId: string): Promise<Booking | null> {
  try {
    const bookingRef = doc(db, 'bookings', bookingId);
    const bookingDoc = await getDoc(bookingRef);

    if (!bookingDoc.exists()) {
      console.log('No booking found with the given ID.');
      return null;
    }

    const data = bookingDoc.data();
    const booking: Booking = {
      ...data as Booking,
      ID: bookingDoc.id,
      StartTime: data.StartTime.toDate(),
      EndTime: data.EndTime.toDate(),
      CreatedAt: data.CreatedAt ? data.CreatedAt.toDate() : new Date(),
      PriceFromCheckoutPage: data.PriceFromCheckoutPage || {
        basePrice: 0,
        fees: [],
        deposit: 0
      },
      PriceWeEarned: data.PriceWeEarned || 0,
      PriceWeProcessed: data.PriceWeProcessed || 0
    };

    return booking;
  } catch (error) {
    console.error('Error getting booking by ID:', error);
    return null;
  }
}

export async function getBookingByIdAndEmail(bookingId: string, email: string): Promise<Booking | null> {
  const bookingsRef = collection(db, 'bookings');
  const q = query(bookingsRef, where('__name__', '==', bookingId), where('UserEmail', '==', email));
  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    console.log('No booking found with the given ID and email.');
    return null;
  }

  const bookingDoc = querySnapshot.docs[0];
  const booking: Booking = {
    ...bookingDoc.data() as Booking,
    ID: bookingDoc.id,
    StartTime: bookingDoc.data().StartTime.toDate(),
    EndTime: bookingDoc.data().EndTime.toDate(),
    CreatedAt: bookingDoc.data().CreatedAt.toDate()
  };

  return booking;
}

export async function updateBooking(booking: Booking): Promise<Booking> {
  const bookingRef = doc(db, 'bookings', booking.ID);

  await updateDoc(bookingRef, {
    UserID: booking.UserID,
    VenueID: booking.VenueID,
    StartTime: booking.StartTime,
    EndTime: booking.EndTime,
    NumberOfGuests: booking.NumberOfGuests,
    PaymentStatus: booking.PaymentStatus,
    BookingStatus: booking.BookingStatus,
    StripeSessionId: booking.StripeSessionId,
    FirstName: booking.FirstName,
    LastName: booking.LastName,
    UserEmail: booking.UserEmail,
    CreatedAt: booking.CreatedAt
  });
  return booking;
}

export async function getBookingsByVenueId(venueId: string, startDate?: Date, endDate?: Date, paymentStatus?: string): Promise<Booking[]> {
  const bookingsRef = collection(db, 'bookings');
  let q = query(bookingsRef, where('VenueID', '==', venueId), orderBy('StartTime', 'desc'));
  if (paymentStatus) {
    q = query(q, where('PaymentStatus', '==', paymentStatus));
  } else {
    q = query(q, where('PaymentStatus', '==', 'completed'));
  }
  if (startDate) {
    const startOfDay = new Date(startDate);
    startOfDay.setHours(0, 0, 0, 0);
    q = query(q, where('StartTime', '>=', startOfDay));
  }
  if (endDate) {
    const endOfDay = new Date(endDate);
    endOfDay.setHours(23, 59, 59, 999);
    q = query(q, where('EndTime', '<=', endOfDay));
  }
  const snapshot = await getDocs(q);
  const bookings: Booking[] = [];

  snapshot.forEach((doc) => {
    const data = doc.data();
    bookings.push({
      ID: doc.id,
      UserID: data.UserID,
      UserEmail: data.UserEmail,
      VenueID: data.VenueID,
      StartTime: data.StartTime.toDate(),
      EndTime: data.EndTime.toDate(),
      SectionID: data.SectionID,
      NumberOfGuests: data.NumberOfGuests,
      PhoneNumber: data.PhoneNumber,
      PaymentStatus: data.PaymentStatus,
      BookingStatus: data.BookingStatus,
      StripeSessionId: data.StripeSessionId,
      FirstName: data.FirstName,
      LastName: data.LastName,
      PriceBreakdown: data.PriceBreakdown,
      EventID: data.EventID,
      CreatedAt: data.CreatedAt.toDate(),
      PriceFromCheckoutPage: data.PriceFromCheckoutPage,
      PriceWeProcessed: data.PriceWeProcessed,
      PriceWeEarned: data.PriceWeEarned
    });
  });

  return bookings;
}

export async function cancelBooking(bookingId: string): Promise<boolean> {
  try {
    const bookingRef = doc(db, 'bookings', bookingId);
    await updateDoc(bookingRef, {
      BookingStatus: 'cancelled'
    });
    return true;
  } catch (error) {
    console.error('Failed to cancel booking:', error);
    return false;
  }
}

export async function getBookingsByUserId(userId: string): Promise<Booking[]> {
  const bookingsRef = collection(db, 'bookings');
  const q = query(bookingsRef, where('UserID', '==', userId));
  const snapshot = await getDocs(q);
  const bookings: Booking[] = [];
  snapshot.forEach((doc) => {
    const data = doc.data();
    bookings.push({
      ID: doc.id,
      UserID: data.UserID,
      UserEmail: data.UserEmail,
      VenueID: data.VenueID,
      StartTime: data.StartTime.toDate(),
      EndTime: data.EndTime.toDate(),
      SectionID: data.SectionID,
      NumberOfGuests: data.NumberOfGuests,
      PhoneNumber: data.PhoneNumber,
      PaymentStatus: data.PaymentStatus,
      BookingStatus: data.BookingStatus,
      StripeSessionId: data.StripeSessionId,
      FirstName: data.FirstName,
      LastName: data.LastName,
      PriceBreakdown: data.PriceBreakdown,
      EventID: data.EventID,
      CreatedAt: data.CreatedAt.toDate(),
      PriceFromCheckoutPage: data.PriceFromCheckoutPage,
      PriceWeProcessed: data.PriceWeProcessed,
      PriceWeEarned: data.PriceWeEarned
    });
  });
  return bookings;
}

export async function getBookingsByEmail(email: string): Promise<Booking[]> {
  const bookingsRef = collection(db, 'bookings');
  const q = query(bookingsRef, where('UserEmail', '==', email));
  const snapshot = await getDocs(q);
  const bookings: Booking[] = [];
  snapshot.forEach((doc) => {
    const data = doc.data();
    bookings.push({
      ID: doc.id,
      UserID: data.UserID,
      UserEmail: data.UserEmail,
      VenueID: data.VenueID,
      StartTime: data.StartTime.toDate(),
      EndTime: data.EndTime.toDate(),
      SectionID: data.SectionID,
      NumberOfGuests: data.NumberOfGuests,
      PhoneNumber: data.PhoneNumber,
      PaymentStatus: data.PaymentStatus,
      BookingStatus: data.BookingStatus,
      StripeSessionId: data.StripeSessionId,
      FirstName: data.FirstName,
      LastName: data.LastName,
      PriceBreakdown: data.PriceBreakdown,
      EventID: data.EventID,
      CreatedAt: data.CreatedAt.toDate(),
      PriceFromCheckoutPage: data.PriceFromCheckoutPage,
      PriceWeProcessed: data.PriceWeProcessed,
      PriceWeEarned: data.PriceWeEarned
    });
  });
  return bookings;
}

export async function getPendingVenueBookedTimeSlotGuessesByVenueId(venueId: string, startDate?: Date, endDate?: Date): Promise<VenueBookedTimeSlotGuess[] | null> {
  const venueBookedTimeSlotGuessesRef = collection(db, 'venue_booked_time_slot_guesses');
  let q = query(venueBookedTimeSlotGuessesRef, where('VenueID', '==', venueId), where('Status', '==', 'pending'));
  if (startDate) {
    q = query(q, where('StartTime', '>=', startDate));
  }
  if (endDate) {
    q = query(q, where('EndTime', '<=', endDate));
  }


  const snapshot = await getDocs(q);
  const venueBookedTimeSlotGuesses: VenueBookedTimeSlotGuess[] = [];
  snapshot.forEach((doc) => {
    const data = doc.data();
    venueBookedTimeSlotGuesses.push({
      ID: doc.id,
      VenueID: data.VenueID,
      StartTime: data.StartTime.toDate(),
      EndTime: data.EndTime.toDate(),
      SectionName: data.SectionName,
      Confidence: data.Confidence,
      OriginalText: data.OriginalText,
      ReasonForGuess: data.ReasonForGuess,
      NeedsHumanReview: data.NeedsHumanReview,
      Status: data.Status,
      EventID: data.EventID,
    });
  });
  return venueBookedTimeSlotGuesses;
}

export async function getAllPendingVenueBookedTimeSlotGuesses(numOfDocs: number, lastDocId?: string, startDate?: Date): Promise<VenueBookedTimeSlotGuess[]> {
  const venueBookedTimeSlotGuessesRef = collection(db, 'venue_booked_time_slot_guesses');
  let q = query(venueBookedTimeSlotGuessesRef, where('Status', '==', 'pending'), orderBy('StartTime'), limit(numOfDocs));

  if (startDate) {
    q = query(q, where('StartTime', '>=', startDate));
  }

  if (lastDocId) {
    const lastDocRef = doc(db, 'venue_booked_time_slot_guesses', lastDocId);
    const lastDocSnapshot = await getDoc(lastDocRef);
    q = query(q, startAfter(lastDocSnapshot));
  }

  const snapshot = await getDocs(q);
  const venueBookedTimeSlotGuesses: VenueBookedTimeSlotGuess[] = [];
  snapshot.forEach((doc) => {
    const data = doc.data();
    venueBookedTimeSlotGuesses.push({
      ID: doc.id,
      VenueID: data.VenueID,
      StartTime: data.StartTime.toDate(),
      EndTime: data.EndTime.toDate(),
      SectionName: data.SectionName,
      Confidence: data.Confidence,
      OriginalText: data.OriginalText,
      ReasonForGuess: data.ReasonForGuess,
      NeedsHumanReview: data.NeedsHumanReview,
      Status: data.Status,
      EventID: data.EventID,
    });
  });
  return venueBookedTimeSlotGuesses;
}


export async function updateVenueBookedTimeSlotGuess(venueBookedTimeSlotGuess: VenueBookedTimeSlotGuess): Promise<VenueBookedTimeSlotGuess> {
  const venueBookedTimeSlotGuessRef = doc(db, 'venue_booked_time_slot_guesses', venueBookedTimeSlotGuess.ID);
  const venueBookedTimeSlotRef = collection(db, 'venue_booked_time_slots');
  const venueDocRef = doc(db, 'venues', venueBookedTimeSlotGuess.VenueID);

  await runTransaction(db, async (transaction) => {
    // Perform all reads before any writes
    const venueDoc = await transaction.get(venueDocRef);
    const venue = venueDoc.data() as Venue;

    // Proceed with the update
    transaction.update(venueBookedTimeSlotGuessRef, {
      VenueID: venueBookedTimeSlotGuess.VenueID,
      StartTime: venueBookedTimeSlotGuess.StartTime,
      EndTime: venueBookedTimeSlotGuess.EndTime,
      SectionName: venueBookedTimeSlotGuess.SectionName,
      Confidence: venueBookedTimeSlotGuess.Confidence,
      OriginalText: venueBookedTimeSlotGuess.OriginalText,
      ReasonForGuess: venueBookedTimeSlotGuess.ReasonForGuess,
      NeedsHumanReview: venueBookedTimeSlotGuess.NeedsHumanReview,
      Status: venueBookedTimeSlotGuess.Status,
    });

    if (venueBookedTimeSlotGuess.Status === 'confirmed') {
      const sectionId = Object.keys(venue.Sections).find(
        (curSectionId) => venue.Sections[curSectionId].Name === venueBookedTimeSlotGuess.SectionName
      ) || '';
      const newVenueBookedTimeSlot = {
        VenueID: venueBookedTimeSlotGuess.VenueID,
        StartTime: venueBookedTimeSlotGuess.StartTime,
        EndTime: venueBookedTimeSlotGuess.EndTime,
        SectionID: sectionId,
        GuessData: {
          SectionName: venueBookedTimeSlotGuess.SectionName,
          Confidence: venueBookedTimeSlotGuess.Confidence,
          OriginalText: venueBookedTimeSlotGuess.OriginalText,
          ReasonForGuess: venueBookedTimeSlotGuess.ReasonForGuess,
        },
      } as VenueBookedTimeSlot;
      transaction.set(doc(venueBookedTimeSlotRef), newVenueBookedTimeSlot);
    }
  });

  return venueBookedTimeSlotGuess;
}

// Create venue booked time slot with start and end time
export async function createVenueBookedTimeSlot(venueId: string, start: Date, end: Date, sectionId: string): Promise<VenueBookedTimeSlot> {
  const venueBookedTimeSlotRef = collection(db, 'venue_booked_time_slots');
  const docRef = await addDoc(venueBookedTimeSlotRef, {
    VenueID: venueId,
    StartTime: start,
    EndTime: end,
    SectionID: sectionId,
    GuessData: {
      Confidence: 1, // 100% confidence since it's manually created
      OriginalText: 'Manually blocked by venue admin',
      ReasonForGuess: 'Manual block',
      IsManuallyCreated: true
    }
  });

  return {
    ID: docRef.id,
    VenueID: venueId,
    StartTime: start,
    EndTime: end,
    SectionID: sectionId,
    GuessData: {
      Confidence: 1,
      OriginalText: 'Manually blocked by venue admin',
      ReasonForGuess: 'Manual block',
      IsManuallyCreated: true
    }
  } as VenueBookedTimeSlot;
}

export async function markGuessAsProcessed(venueBookedTimeSlotGuessId: string): Promise<void> {
  const venueBookedTimeSlotGuessRef = doc(db, 'venue_booked_time_slot_guesses', venueBookedTimeSlotGuessId);
  await updateDoc(venueBookedTimeSlotGuessRef, {
    Status: 'processed',
  });
}

export async function markGuessAsDenied(venueBookedTimeSlotGuessId: string): Promise<void> {
  const venueBookedTimeSlotGuessRef = doc(db, 'venue_booked_time_slot_guesses', venueBookedTimeSlotGuessId);
  await updateDoc(venueBookedTimeSlotGuessRef, {
    Status: 'rejected',
  });
}

// Delete Venue Booked Time Slot
export async function deleteVenueBookedTimeSlot(venue_booked_time_slot_id: string): Promise<void> {
  const venueBookedTimeSlotRef = doc(db, 'venue_booked_time_slots', venue_booked_time_slot_id);
  await deleteDoc(venueBookedTimeSlotRef);
}

export async function handleDeleteAllBookings(venueId: string): Promise<boolean> {
  try {
    if (typeof venueId !== 'string') {
      throw new Error('Invalid venueId: must be a string');
    }

    // Execute all queries in parallel
    const [
      bookedSlotsSnapshot,
      guessesSnapshot,
      processedCalendarEventsSnapshot,
      rawCalendarEventsSnapshot
    ] = await Promise.all([
      getDocs(query(collection(db, 'venue_booked_time_slots'),
        where('VenueID', '==', venueId))),
      getDocs(query(collection(db, 'venue_booked_time_slot_guesses'),
        where('VenueID', '==', venueId))),
      getDocs(collection(db, `venues/${venueId}/processed_calendar_events`)),
      getDocs(query(collection(db, 'raw_calendar_events'),
        where('VenueID', '==', venueId)))
    ]);

    // Combine all docs that need to be deleted
    const allDocs = [
      ...bookedSlotsSnapshot.docs,
      ...guessesSnapshot.docs,
      ...processedCalendarEventsSnapshot.docs,
      ...rawCalendarEventsSnapshot.docs
    ];

    // Process in chunks of 500
    const chunkSize = 500;
    for (let i = 0; i < allDocs.length; i += chunkSize) {
      const chunk = allDocs.slice(i, i + chunkSize);
      const batch = writeBatch(db);

      chunk.forEach(doc => batch.delete(doc.ref));
      await batch.commit();
    }

    return true;
  } catch (error) {
    console.error('Error deleting bookings:', error);
    return false;
  }
}

// GetVenueBookedTimeSlots
export async function getVenueBookedTimeSlotsByDay(venueId: string,
  startDate?: Date, endDate?: Date): Promise<{ [key: string]: VenueBookedTimeSlot[] }> {
  const bookingsRef = collection(db, 'venue_booked_time_slots');
  let q = query(bookingsRef, where('VenueID', '==', venueId));
  if (startDate) {
    const startOfDay = new Date(startDate);
    startOfDay.setHours(0, 0, 0, 0);
    q = query(q, where('StartTime', '>=', startOfDay));
  }
  if (endDate) {
    const endOfDay = new Date(endDate);
    endOfDay.setHours(23, 59, 59, 999);
    q = query(q, where('EndTime', '<=', endOfDay));
  }
  const snapshot = await getDocs(q);
  const bookings: VenueBookedTimeSlot[] = [];
  snapshot.forEach((doc) => {
    const data = doc.data();
    const startTime = data.StartTime.toDate();
    const endTime = data.EndTime.toDate();

    // If end time is before start time, add a day to end time
    if (endTime < startTime) {
      endTime.setDate(endTime.getDate() + 1);
    }

    bookings.push({
      ID: doc.id,
      VenueID: data.VenueID,
      StartTime: startTime,
      EndTime: endTime,
      SectionID: data.SectionID,
      GuessData: data.GuessData
    });
  });

  const bookingsByDay: { [key: string]: VenueBookedTimeSlot[] } = {};
  bookings.forEach((booking) => {
    const date = booking.StartTime.toDateString();
    if (!bookingsByDay[date]) {
      bookingsByDay[date] = [];
    }
    bookingsByDay[date].push(booking);
  });

  const venue = await getVenueById(venueId);
  // For each day's bookings, check for sections that overlap all others
  Object.keys(bookingsByDay).forEach(date => {
    const dayBookings = [...bookingsByDay[date]]; // Create copy to avoid modifying while iterating

    dayBookings.forEach(booking => {
      // Get the section from venue that matches this booking
      const section = venue?.Sections?.[booking.SectionID];

      // If this section overlaps all others, add a booking for each other section
      if (section?.OverlapsAllOtherSections) {
        Object.keys(venue.Sections).forEach(sectionId => {
          if (sectionId !== booking.SectionID) {
            // Add duplicate booking with different section ID
            bookingsByDay[date].push({
              ...booking,
              SectionID: sectionId
            });
          }
        });
      }
      // For each section that overlaps this booking's section, add a duplicate booking
      Object.entries(venue.Sections).forEach(([sectionId, section]) => {
        // Add booking for sections that overlap all others
        if (section.OverlapsAllOtherSections && sectionId !== booking.SectionID) {
          bookingsByDay[date].push({
            ...booking,
            SectionID: sectionId
          });
        }

        // Add booking for sections that are parents of this section
        if (section.IsParentOfSectionIDs?.includes(booking.SectionID)) {
          bookingsByDay[date].push({
            ...booking,
            SectionID: sectionId
          });
        }
      });
    });
  });


  return bookingsByDay;
}

// GetVenueBookedTimeSlotsForMultipleVenues
export async function getVenueBookedTimeSlotsForMultipleVenues(
  venueIds: string[],
  startDate?: Date,
  endDate?: Date
): Promise<{ [venueId: string]: { [date: string]: VenueBookedTimeSlot[] } }> {
  const bookingsRef = collection(db, 'venue_booked_time_slots');
  const bookingsByVenue: { [venueId: string]: { [date: string]: VenueBookedTimeSlot[] } } = {};

  // Paginate through venueIds in chunks of 30
  for (let i = 0; i < venueIds.length; i += 30) {
    const venueIdChunk = venueIds.slice(i, i + 30);
    let q = query(bookingsRef, where('VenueID', 'in', venueIdChunk));

    if (startDate) {
      q = query(q, where('StartTime', '>=', startDate));
    }
    if (endDate) {
      q = query(q, where('EndTime', '<=', endDate));
    }

    const snapshot = await getDocs(q);

    snapshot.forEach((doc) => {
      const data = doc.data();
      const startTime = data.StartTime.toDate();
      const endTime = data.EndTime.toDate();

      // If end time is before start time, add a day to end time
      if (endTime < startTime) {
        endTime.setDate(endTime.getDate() + 1);
      }

      const booking: VenueBookedTimeSlot = {
        ID: doc.id,
        VenueID: data.VenueID,
        StartTime: startTime,
        EndTime: endTime,
        SectionID: data.SectionID,
      };

      const date = booking.StartTime.toDateString();

      if (!bookingsByVenue[booking.VenueID]) {
        bookingsByVenue[booking.VenueID] = {};
      }
      if (!bookingsByVenue[booking.VenueID][date]) {
        bookingsByVenue[booking.VenueID][date] = [];
      }
      bookingsByVenue[booking.VenueID][date].push(booking);
    });
  }

  return bookingsByVenue;
}

// This function updates a booking's status to confirmed, pending, or cancelled
export async function updateBookingStatus(
  bookingId: string,
  status: 'confirmed' | 'pending' | 'cancelled' | 'invoice_sent' | 'all_payments_received' | 'waiting_for_event'
): Promise<boolean> {
  try {
    const bookingRef = doc(db, 'bookings', bookingId);
    await updateDoc(bookingRef, {
      BookingStatus: status
    });
    return true;
  } catch (error) {
    console.error('Failed to update booking status:', error);
    return false;
  }
}

// This function deletes a booking completely from Firestore
export async function deleteBooking(bookingId: string): Promise<boolean> {
  try {
    const bookingRef = doc(db, 'bookings', bookingId);
    await deleteDoc(bookingRef);
    return true;
  } catch (error) {
    console.error('Failed to delete booking:', error);
    return false;
  }
}
