import { AdditionalFee, PricingRate, Venue } from '../../../shared/models';
import dayjs from 'dayjs';

const PRINT_DEBUG_LOG = false;

export const GetFullPrice = (
  startTime: string | Date,
  endTime: string | Date,
  pricingRate: PricingRate,
  numberOfPeople: number,
  includeOpenBar: boolean,
): number => {
  let totalPrice = 0;
  
  // Convert input parameters to dayjs objects
  const startDayjs = dayjs(startTime);
  const endDayjs = dayjs(endTime);
  
  // Validate inputs
  if (!startDayjs.isValid() || !endDayjs.isValid()) {
    console.error('Invalid date inputs:', { startTime, endTime });
    return 0;
  }
  
  let currentDayjs = startDayjs;

  while (currentDayjs.isBefore(endDayjs)) {
    const hourEndDayjs = currentDayjs.add(1, 'hour');
    const isLastHour = hourEndDayjs.isAfter(endDayjs);
    
    // Calculate hour fraction for partial hours
    const hourFraction = isLastHour 
      ? endDayjs.diff(currentDayjs, 'hour', true) 
      : 1;

    // Convert dayjs to Date for compatibility with existing functions
    const currentTimeDate = currentDayjs.toDate();
    const { rate } = getRate(currentTimeDate, pricingRate);
    const baseHourlyRate = rate || 0;
    
    if (pricingRate == undefined) {
      console.log(`Pricing rate is undefined for time: ${currentDayjs.format()}`);
    }
    
    const hourlyPricePerPerson = pricingRate ? pricingRate.RatePerPerson ?? 0 : 0;
    const openBarPricePerPerson = includeOpenBar ? pricingRate ? pricingRate.AdditionalCostPerPersonForOpenBar ?? 0 : 0 : 0;

    const hourPrice = (baseHourlyRate + (hourlyPricePerPerson + openBarPricePerPerson) * numberOfPeople) * hourFraction;
    totalPrice += hourPrice;

    currentDayjs = hourEndDayjs;
  }

  const minimumBasePrice = (pricingRate.RatePerHour ?? 0) === 0 
    ? pricingRate.MinimumBookingDuration * (pricingRate.RatePerPerson ?? 0) * numberOfPeople
    : pricingRate.MinimumBookingDuration * pricingRate.RatePerHour;

  const finalPrice = Math.max(totalPrice, minimumBasePrice);

  if (PRINT_DEBUG_LOG) {
    console.log(`Calculated booking price: $${finalPrice.toFixed(2)}`);
    console.log(`Breakdown:`);
    console.log(`- Start time: ${startDayjs.format()}`);
    console.log(`- End time: ${endDayjs.format()}`);
    console.log(`- Number of people: ${numberOfPeople}`);
    console.log(`- Open bar included: ${includeOpenBar}`);

    let debugDayjs = startDayjs;
    while (debugDayjs.isBefore(endDayjs)) {
      const hourEndDayjs = debugDayjs.add(1, 'hour');
      const isLastHour = hourEndDayjs.isAfter(endDayjs);
      const hourFraction = isLastHour ? endDayjs.diff(debugDayjs, 'hour', true) : 1;

      const { rate } = getRate(debugDayjs.toDate(), pricingRate);
      const baseHourlyRate = rate || 0;

      console.log(`  Hour ${debugDayjs.format('HH:mm')} - ${hourEndDayjs.format('HH:mm')}:`);
      console.log(`    - Rate: $${baseHourlyRate.toFixed(2)}`);
      console.log(`    - Fraction of hour: ${hourFraction.toFixed(2)}`);

      debugDayjs = hourEndDayjs;
    }
  }

  return finalPrice;
}


export const addAdditionalFees = (price: number, additionalFees?: AdditionalFee[]): number => {
  if (PRINT_DEBUG_LOG) {
    console.log('Adding additional fees');
    console.log(`Initial price: $${price.toFixed(2)}`);
  }
  
  if (!additionalFees || additionalFees.length === 0) {
    if (PRINT_DEBUG_LOG) {
      console.log('No additional fees to add');
    }
    return price;
  }

  if (PRINT_DEBUG_LOG) {
    console.log(`Number of additional fees: ${additionalFees.length}`);
  }
  
  let totalPrice = price;
  for (const fee of additionalFees) {
    if (PRINT_DEBUG_LOG) {
      console.log(`Processing fee: ${JSON.stringify(fee)}`);
    }
    
    if (fee.type === 'percentage') {
      const feeAmount = price * fee.value / 100;
      if (PRINT_DEBUG_LOG) {
        console.log(`Adding percentage fee: ${fee.value}% = $${feeAmount.toFixed(2)}`);
      }
      totalPrice += feeAmount;
    } else if (fee.type === 'flat') {
      if (PRINT_DEBUG_LOG) {
        console.log(`Adding flat fee: $${fee.value.toFixed(2)}`);
      }
      totalPrice += fee.value;
    }
    if (PRINT_DEBUG_LOG) {
      console.log(`Running total after fee: $${totalPrice.toFixed(2)}`);
    }
  }
  
  if (PRINT_DEBUG_LOG) {
    console.log(`Final price after all fees: $${totalPrice.toFixed(2)}`);
  }
  return totalPrice;
}

function getRate(time: Date, pricingRate: PricingRate): { rate: number } {
  if (PRINT_DEBUG_LOG) {
    console.log('Calculating rate for time:', time.toLocaleString());
    console.log('Pricing rate config:', JSON.stringify(pricingRate, null, 2));
  }
  
  const isWeekend = time.getDay() === 5 || time.getDay() === 6; // Friday or Saturday
  const isNight = time.getHours() >= (pricingRate ? pricingRate.NightStartHour : 18); // Default to 6 PM if not defined
  
  if (PRINT_DEBUG_LOG) {
    console.log(`Is weekend: ${isWeekend}`);
    console.log(`Is night: ${isNight} (Night starts at hour ${pricingRate ? pricingRate.NightStartHour : 18})`);
  }

  if (isWeekend && isNight) {
    const rate = (pricingRate.FridayAndSaturdayNightRate || 0) || 
                 ((pricingRate.RatePerHour || 0) * (pricingRate.FridayAndSaturdayNightMultiplier || 1));
    if (PRINT_DEBUG_LOG) {
      console.log(`Using Weekend Night rate: $${rate.toFixed(2)}`);
    }
    return { rate };
  } else if (isWeekend) {
    const rate = (pricingRate.FridayAndSaturdayRate || 0) || 
                 ((pricingRate.RatePerHour || 0) * (pricingRate.FridayAndSaturdayMultiplier || 1));
    if (PRINT_DEBUG_LOG) {
      console.log(`Using Weekend rate: $${rate.toFixed(2)}`);
    }
    return { rate };
  } else if (isNight) {
    const rate = (pricingRate.WeekdayNightRate || 0) || 
                 ((pricingRate.RatePerHour || 0) * (pricingRate.WeekdayNightMultiplier || 1));
    if (PRINT_DEBUG_LOG) {
      console.log(`Using Weekday Night rate: $${rate.toFixed(2)}`);
    }
    return { rate };
  }

  if (PRINT_DEBUG_LOG) {
    console.log(`Using Default rate: $${(pricingRate ? pricingRate.RatePerHour ?? 0 : 0).toFixed(2)}`);
  }
  return { rate: pricingRate ? pricingRate.RatePerHour ?? 0 : 0 };
}