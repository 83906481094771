'use client';
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { Analytics, getAnalytics, logEvent } from 'firebase/analytics';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import {
  signInWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  User,
  sendPasswordResetEmail,
} from 'firebase/auth';
import { useState, useEffect } from 'react';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDJSWI9Di5iJC_Sy8HGI6QczaewULffkEw',
  authDomain: 'rentabar-3d509.firebaseapp.com',
  projectId: 'rentabar-3d509',
  storageBucket: 'rentabar-3d509.appspot.com',
  messagingSenderId: '1068798323193',
  appId: '1:1068798323193:web:7425814553191010cd3528',
  measurementId: 'G-JY12D9N2X2',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);

if (process.env.NODE_ENV === 'development') {
  console.log('Running in dev mode');
  // connectFirestoreEmulator(db, 'localhost', 8080);
  // connectAuthEmulator(auth, "http://localhost:9099");
}
// Initialize Firebase Authentication

// Init analytics if window is defined and measurement ID
export let analytics: Analytics | null = null;
if (typeof window !== 'undefined' && firebaseConfig.measurementId) {
  analytics = getAnalytics(app);
}

// Function to log events to Firebase Analytics
export function logFirebaseEvent(eventName: string, eventParams: Record<string, any>) {
  if (analytics) {
    const user = getAuth().currentUser;
    if (user) {
      eventParams.email = user.email;
    }
    logEvent(analytics, eventName, eventParams);
  } else {
    console.warn('Analytics is not initialized');
  }
}

// Custom hook to directly read auth state without a listener
export function useAuthState() {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Wait for Firebase auth to initialize before checking current user
    const checkAuthOnce = async () => {
      try {
        // Wait for auth to be ready - this ensures Firebase has had time to restore auth state
        await auth.authStateReady();

        // Now get the current user - this will be accurate after initialization
        const currentUser = auth.currentUser;
        setUser(currentUser);
      } catch (error) {
        console.error("Error checking auth state:", error);
      } finally {
        setLoading(false);
      }
    };

    checkAuthOnce();
  }, []);

  // Force refresh function to manually check auth state
  const refreshAuthState = () => {
    setUser(auth.currentUser);
  };

  return { user, loading, refreshAuthState };
}

// Function to get current user
export function getCurrentUser(): User | null {
  return auth.currentUser;
}

// Function to check if user is logged in
export function isLoggedIn(): boolean {
  return !!auth.currentUser;
}

// Function to sign in with Google
export async function signInWithGoogle() {
  const provider = new GoogleAuthProvider();
  try {
    const result = await signInWithPopup(auth, provider);

    // Get the user's email and ensure it's normalized in our database
    if (result.user && result.user.email) {
      const normalizedEmail = result.user.email.trim().toLowerCase();

      // If Firebase hasn't normalized the email already, log it for monitoring
      if (normalizedEmail !== result.user.email) {
        console.log(`Email normalized from ${result.user.email} to ${normalizedEmail}`);
        // Firebase manages the auth email itself, but we can log this difference
        // The userSignedUp function will handle normalization in Firestore
      }
    }

    return result.user;
  } catch (error) {
    console.error('Error signing in with Google', error);
    throw error;
  }
}

// Function to sign in with email and password
export async function signIn(email: string, password: string) {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return userCredential.user;
  } catch (error) {
    console.error('Error signing in with email and password', error);
    throw error;
  }
}

// Function to sign out
export async function signOut() {
  try {
    await auth.signOut();
  } catch (error) {
    console.error('Error signing out', error);
    throw error;
  }
}

// Function to send password reset email
export async function sendPasswordReset(email: string) {
  try {
    await sendPasswordResetEmail(auth, email);
    return true;
  } catch (error) {
    console.error('Error sending password reset email', error);
    throw error;
  }
}

export { getAuth };
