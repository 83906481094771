import { collection, doc, getDocs, runTransaction, query, deleteDoc, setDoc } from 'firebase/firestore';
import { HomepageCategory } from '../../../../shared/models';
import { db } from './app';

const COLLECTION_NAME = 'homepage_categories';

export async function getAllHomepageCategories(): Promise<HomepageCategory[]> {
    const categoriesRef = collection(db, COLLECTION_NAME);
    const querySnapshot = await getDocs(query(categoriesRef));
    
    return querySnapshot.docs.map(doc => ({
        ID: doc.id,
        ...doc.data()
    } as HomepageCategory));
}

export async function updateHomepageCategory(category: HomepageCategory): Promise<void> {
    const oldCategoryRef = doc(db, COLLECTION_NAME, category.ID);
    
    try {
        await runTransaction(db, async (transaction) => {
            const categoryDoc = await transaction.get(oldCategoryRef);
            
            if (!categoryDoc.exists()) {
                // If old document doesn't exist, create a new one with the current city name
                const newCategoryRef = doc(db, COLLECTION_NAME, category.City);
                transaction.set(newCategoryRef, {
                    City: category.City,
                    CategoriesAndVenuesIDsInOrder: category.CategoriesAndVenuesIDsInOrder
                });
                return;
            }

            // If the city name has changed
            if (category.ID !== category.City) {
                // Create new document with new city name
                const newCategoryRef = doc(db, COLLECTION_NAME, category.City);
                transaction.set(newCategoryRef, {
                    City: category.City,
                    CategoriesAndVenuesIDsInOrder: category.CategoriesAndVenuesIDsInOrder
                });
                
                // Delete the old document
                transaction.delete(oldCategoryRef);
            } else {
                // If city name hasn't changed, just update the existing document
                transaction.update(oldCategoryRef, {
                    City: category.City,
                    CategoriesAndVenuesIDsInOrder: category.CategoriesAndVenuesIDsInOrder
                });
            }
        });
    } catch (error) {
        console.error('Error updating category:', error);
        throw error;
    }
}

export async function createHomepageCategory(category: HomepageCategory): Promise<void> {
    const categoryRef = doc(db, COLLECTION_NAME, category.City); // Use City as the document ID
    
    await setDoc(categoryRef, {
        City: category.City,
        CategoriesAndVenuesIDsInOrder: category.CategoriesAndVenuesIDsInOrder
    });
}

export async function deleteHomepageCategory(categoryId: string): Promise<void> {
    const categoryRef = doc(db, COLLECTION_NAME, categoryId);
    
    await runTransaction(db, async (transaction) => {
        const categoryDoc = await transaction.get(categoryRef);
        
        if (!categoryDoc.exists()) {
            throw new Error('Category does not exist!');
        }

        transaction.delete(categoryRef);
    });
}
