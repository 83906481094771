import { collection, doc, getDoc, getDocs, setDoc, query, orderBy, deleteDoc, updateDoc } from 'firebase/firestore';
import { BlogPost } from '../../../../shared/models';
import { db } from './app';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject, getMetadata } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { revalidatePathAction, revalidateCache } from '../../actions/revalidate';

const BLOG_COLLECTION = 'blog_posts';

export async function getBlogPost(id: string): Promise<BlogPost | null> {
    try {
        const docRef = doc(db, BLOG_COLLECTION, id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const data = docSnap.data() as BlogPost;
            data.ID = id;
            return data;
        }
        return null;
    } catch (error) {
        console.error(`[getBlogPost] Error getting blog post with id ${id}:`, error);
        throw error;
    }
}
export async function getAllBlogPosts(includeDisabled: boolean = false): Promise<BlogPost[]> {
    try {
        // Fixed extra spaces in PublishDate field name that was causing the orderBy to fail
        const q = query(collection(db, BLOG_COLLECTION), orderBy('PublishDate', 'desc'));
        const querySnapshot = await getDocs(q);

        const posts = querySnapshot.docs.map(doc => {
            const data = doc.data();
            return {
                ID: doc.id,
                LastModified: data.LastModified || new Date(),
                Author: data.Author || '',
                Title: data.Title || '',
                Subtitle: data.Subtitle || '',
                Content: data.Content || [],
                Excerpt: data.Excerpt || '',
                PublishDate: data.PublishDate || new Date().toISOString(),
                HeadlineImageUrl: data.HeadlineImageUrl || '',
                // For backward compatibility, treat undefined as enabled
                Enabled: data.Enabled !== undefined ? data.Enabled : true
            } as BlogPost;
        });

        // If includeDisabled is false, filter out disabled posts
        return includeDisabled ? posts : posts.filter(post => post.Enabled !== false);
    } catch (error) {
        console.error('[getAllBlogPosts] Error getting all blog posts:', error);
        throw error;
    }
}

export async function createBlogPost(blogPost: Omit<BlogPost, 'id'>): Promise<string> {
    try {
        const docRef = doc(collection(db, BLOG_COLLECTION));
        const postWithId = {
            ...blogPost,
            ID: docRef.id,
            // Ensure Enabled is defined (default to true for backward compatibility)
            Enabled: blogPost.Enabled !== undefined ? blogPost.Enabled : true
        };
        postWithId.LastModified = new Date();
        await setDoc(docRef, postWithId);
        return docRef.id;
    } catch (error) {
        console.error('[createBlogPost] Error creating blog post:', error);
        throw error;
    }
}

export async function updateBlogPost(id: string, blogPost: Partial<BlogPost>): Promise<void> {
    try {
        const docRef = doc(db, BLOG_COLLECTION, id);

        // Update LastModified timestamp
        const updatedPost = {
            ...blogPost,
            LastModified: new Date()
        };

        // If Enabled is not specified, don't change it (don't set a default)
        // This allows partial updates without affecting the Enabled status

        await updateDoc(docRef, updatedPost);
        revalidatePathAction('/blog');
        revalidateCache('blog_post');
    } catch (error) {
        console.error('[updateBlogPost] Error updating blog post:', error);
        throw error;
    }
}

export async function deleteBlogPost(id: string): Promise<void> {
    try {
        // First get the blog post to get image URLs
        const post = await getBlogPost(id);
        if (!post) {
            throw new Error('Blog post not found');
        }

        // Delete all images from storage
        const storage = getStorage();
        const imageDeletePromises: Promise<void>[] = [];

        console.log('[deleteBlogPost] Processing content blocks for image deletion');
        post.Content?.forEach((content, index) => {
            if (content.Type === 'images' && content.ImageUrls) {
                console.log(`[deleteBlogPost] Found image block at index ${index} with ${content.ImageUrls.length} images`);
                content.ImageUrls.forEach((url, imageIndex) => {
                    // Extract the path from the URL
                    console.log(`[deleteBlogPost] Processing image ${imageIndex} with URL: ${url}`);
                    try {
                        const urlPath = decodeURIComponent(url.split('/o/')[1].split('?')[0]);
                        console.log(`[deleteBlogPost] Extracted path: ${urlPath}`);
                        const imageRef = ref(storage, urlPath);
                        imageDeletePromises.push(deleteObject(imageRef));
                    } catch (error) {
                        console.log('url', url);
                        console.error(`[deleteBlogPost] Error parsing URL ${url}:`, error);
                    }
                });
            }
        });
        console.log(`[deleteBlogPost] Added ${imageDeletePromises.length} images to deletion queue`);

        // Wait for all images to be deleted
        if (imageDeletePromises.length > 0) {
            await Promise.all(imageDeletePromises);
        }

        // Delete the blog post document
        const docRef = doc(db, BLOG_COLLECTION, id);
        await deleteDoc(docRef);
        revalidatePathAction('/blog');
    } catch (error) {
        console.error('[deleteBlogPost] Error deleting blog post:', error);
        throw error;
    }
}
export async function deleteBlogImage(blogId: string, imageUrl: string): Promise<void> {
    try {
        const storage = getStorage();

        // Extract the path from the URL
        console.log('imageUrl', imageUrl);

        // Handle different URL formats
        let urlPath;
        if (imageUrl.includes('/o/')) {
            // Format: https://storage.googleapis.com/[bucket]/o/[path]?[params]
            urlPath = decodeURIComponent(imageUrl.split('/o/')[1].split('?')[0]);
        } else if (imageUrl.includes('.appspot.com/')) {
            // Format: https://storage.googleapis.com/[bucket].appspot.com/[path]
            urlPath = imageUrl.split('.appspot.com/')[1];
        } else {
            throw new Error('Unsupported image URL format');
        }

        const imageRef = ref(storage, urlPath);

        try {
            // Check if the file exists before attempting to delete
            await getMetadata(imageRef);
            // Delete the image from storage
            await deleteObject(imageRef);
        } catch (fileError) {
            // If the file doesn't exist, log it but don't throw an error
            if (fileError.code === 'storage/object-not-found') {
                console.warn(`Image does not exist in storage: ${urlPath}`);
                // Continue execution without throwing an error
            } else {
                // For other errors, rethrow
                throw fileError;
            }
        }
    } catch (error) {
        console.error('Error deleting blog image:', error);
        throw error;
    }
}


export async function uploadBlogImage(
    file: File,
    blogId: string,
    contentId: string,
    onProgress?: (progress: number) => void
): Promise<string> {
    try {
        const storage = getStorage();
        const fileExtension = file.name.split('.').pop();
        const shortUuid = uuidv4().substring(0, 4);
        const fileName = `${shortUuid}.${fileExtension}`;
        const path = `blog_images/${blogId}/${contentId}/${fileName}`;
        const storageRef = ref(storage, path);

        // Create a custom implementation to track progress
        // Firebase storage doesn't have native progress tracking in uploadBytes
        // We'll simulate progress updates
        if (onProgress) {
            // Start progress indicator
            onProgress(0);

            // Create artificial progress updates
            const progressInterval = setInterval(() => {
                // Random progress between current and 90%
                const randomProgress = Math.min(90, Math.floor(Math.random() * 30) + 60);
                onProgress(randomProgress);
            }, 500);

            // Upload the file
            await uploadBytes(storageRef, file);

            // Clear interval and set to 100%
            clearInterval(progressInterval);
            onProgress(100);
        } else {
            // Upload without progress tracking
            await uploadBytes(storageRef, file);
        }

        return path;
    } catch (error) {
        console.error('Error uploading blog image:', error);
        throw error;
    }
}

export async function uploadMultipleBlogImages(
    files: File[],
    blogId: string,
    contentId: string,
    onProgress?: (progress: number) => void
): Promise<string[]> {
    try {
        const imageUploadPromises: Promise<string>[] = [];
        const totalFiles = files.length;

        // Initialize overall progress if needed
        if (onProgress) {
            onProgress(0);
        }

        // Create upload promises for each file
        files.forEach((file, index) => {
            // Create a progress handler for this specific file
            const handleFileProgress = (fileProgress: number) => {
                if (onProgress) {
                    // Calculate overall progress based on all files
                    // Each file contributes equally to the overall progress
                    const overallProgress = Math.floor(
                        ((index * 100) + fileProgress) / totalFiles
                    );
                    onProgress(Math.min(overallProgress, 99)); // Cap at 99% until fully complete
                }
            };

            imageUploadPromises.push(uploadBlogImage(file, blogId, contentId, handleFileProgress));
        });

        // Wait for all uploads to complete and return array of URLs
        const uploadPaths = await Promise.all(imageUploadPromises);

        // Set final progress to 100%
        if (onProgress) {
            onProgress(100);
        }

        return uploadPaths;
    } catch (error) {
        console.error('Error uploading multiple blog images:', error);
        throw error;
    }
}

export async function refreshBlogPostCache(id: string): Promise<{ success: boolean; message: string }> {
    try {
        // Get the blog post to verify it exists
        const post = await getBlogPost(id);
        if (!post) {
            return { success: false, message: 'Blog post not found' };
        }

        // Revalidate the specific blog post by ID
        const tagResult = await revalidateCache('blog_post_by_id');

        // Also revalidate the specific post's tag
        const specificTagResult = await revalidateCache(`blog_post_${id}`);

        // Revalidate the blog list page
        const pathResult = await revalidatePathAction('/blog');

        // Revalidate the specific blog post page
        await revalidatePathAction(`/blog/${id}`);

        return {
            success: true,
            message: `Cache refreshed for blog post: ${post.Title}`
        };
    } catch (error) {
        console.error('[refreshBlogPostCache] Error refreshing blog post cache:', error);
        return {
            success: false,
            message: `Failed to refresh cache: ${error instanceof Error ? error.message : String(error)}`
        };
    }
}