import { db } from './app';
import { doc, setDoc, getDoc, arrayUnion, arrayRemove } from 'firebase/firestore';

// ... existing code ...

export async function updateAttributeIcon(
  attributeName: string,
  iconName: string
) {
  const attributesRef = doc(db, 'metadata', 'attributes');
  attributeName = attributeName.toLowerCase();
  try {
    await setDoc(attributesRef, { [attributeName]: iconName }, { merge: true });
    console.log(`Icon for ${attributeName} updated successfully`);
  } catch (error) {
    console.error('Error updating attribute icon:', error);
    throw error;
  }
}

export async function getAttributeIcons() {
  const attributesRef = doc(db, 'metadata', 'attributes');

  try {
    const docSnap = await getDoc(attributesRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      const lowerCaseData = Object.keys(data).reduce((acc, key) => {
        acc[key.toLowerCase()] = data[key];
        return acc;
      }, {});
      return lowerCaseData;
    } else {
      return {};
    }
  } catch (error) {
    console.error('Error getting attribute icons:', error);
    throw error;
  }
}

import { deleteField } from 'firebase/firestore';

export async function deleteAttribute(attributeName: string) {
  const attributesRef = doc(db, 'metadata', 'attributes');
  attributeName = attributeName.toLowerCase();
  try {
    const docSnap = await getDoc(attributesRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      if (data && data[attributeName]) {
        await setDoc(
          attributesRef,
          { [attributeName]: deleteField() },
          { merge: true }
        );
        console.log(`Attribute ${attributeName} deleted successfully`);
      } else {
        console.log(`Attribute ${attributeName} does not exist`);
      }
    } else {
      console.log('No attributes found');
    }
  } catch (error) {
    console.error('Error deleting attribute:', error);
    throw error;
  }
}

// Blog post categories

/**
 * Get all blog post categories from Firestore
 * @returns Array of category strings
 */
export async function getBlogPostCategories(): Promise<string[]> {
  try {
    const categoriesRef = doc(db, 'metadata', 'blog_post_categories');
    const docSnap = await getDoc(categoriesRef);

    if (docSnap.exists() && docSnap.data().categories) {
      return docSnap.data().categories;
    } else {
      return [];
    }
  } catch (error) {
    console.error('Error getting blog post categories:', error);
    throw error;
  }
}

/**
 * Add a new blog post category to Firestore
 * @param category Category string to add
 */
export async function addBlogPostCategory(category: string): Promise<void> {
  try {
    if (!category.trim()) {
      console.warn('Attempted to add empty category');
      return;
    }

    const categoriesRef = doc(db, 'metadata', 'blog_post_categories');
    await setDoc(categoriesRef, {
      categories: arrayUnion(category.trim())
    }, { merge: true });

    console.log(`Blog post category "${category}" added successfully`);
  } catch (error) {
    console.error('Error adding blog post category:', error);
    throw error;
  }
}

/**
 * Remove a blog post category from Firestore
 * @param category Category string to remove
 */
export async function removeBlogPostCategory(category: string): Promise<void> {
  try {
    const categoriesRef = doc(db, 'metadata', 'blog_post_categories');
    await setDoc(categoriesRef, {
      categories: arrayRemove(category)
    }, { merge: true });

    console.log(`Blog post category "${category}" removed successfully`);
  } catch (error) {
    console.error('Error removing blog post category:', error);
    throw error;
  }
}

/**
 * Update blog post categories with a new set
 * @param categories Array of category strings
 */
export async function updateBlogPostCategories(categories: string[]): Promise<void> {
  try {
    // Filter out empty categories
    const validCategories = categories.filter(cat => cat.trim().length > 0);

    const categoriesRef = doc(db, 'metadata', 'blog_post_categories');
    await setDoc(categoriesRef, {
      categories: validCategories
    });

    console.log(`Blog post categories updated successfully`);
  } catch (error) {
    console.error('Error updating blog post categories:', error);
    throw error;
  }
}
