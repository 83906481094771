import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db } from './app';
import { collection, deleteDoc, doc, getDoc, setDoc, updateDoc, runTransaction } from 'firebase/firestore';

import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { Venue, Section, VenueGoogleCalendarAccess } from '../../../../shared/models';
interface UploadFileOptions {
  files: File[];
  venueId: string;
  sectionId?: string;
}
export async function uploadFilesToStorageAndStoreURLs(
  options: UploadFileOptions & { onProgress?: (progress: number) => void }
): Promise<void> {
  const storage = getStorage();
  const relativePathsToAdd: string[] = [];

  const totalFiles = options.files.length;
  let uploadedFiles = 0;

  // Upload all files to storage first
  for (const file of options.files) {
    const fileExtension = file.name.split('.').pop();
    const uuid = crypto.randomUUID().substring(0, 4);
    const newFileName = `${uuid}.${fileExtension}`;

    let filePath: string;
    let fileRef;

    if (options.sectionId) {
      filePath = `/venues/${options.venueId}/sections/${options.sectionId}/${newFileName}`;
      fileRef = ref(storage, filePath);
      // Store only the relative path
      relativePathsToAdd.push(`/venues/${options.venueId}/sections/${options.sectionId}/${newFileName}`);
    } else {
      filePath = `venues/${options.venueId}/${newFileName}`;
      fileRef = ref(storage, filePath);
      // Store only the relative path
      relativePathsToAdd.push(`/venues/${options.venueId}/${newFileName}`);
    }

    await uploadBytes(fileRef, file);
    uploadedFiles++;
    if (options.onProgress) {
      options.onProgress((uploadedFiles / totalFiles) * 100);
    }
  }

  // Now update the venue document with the new relative paths using a transaction
  const venueRef = doc(db, 'venues', options.venueId);
  await runTransaction(db, async (transaction) => {
    // Get latest venue data in transaction
    const venueDoc = await transaction.get(venueRef);
    if (!venueDoc.exists()) {
      throw new Error('Venue not found');
    }

    const latestVenue = venueDoc.data() as Venue;

    // Update the appropriate field based on whether it's a venue or section image
    if (options.sectionId) {
      // Ensure the section and Images array exist
      if (!latestVenue.Sections) latestVenue.Sections = {};
      if (!latestVenue.Sections[options.sectionId]) {
        latestVenue.Sections[options.sectionId] = {
          Name: '',
          Capacity: 0,
          Description: '',
          Images: [],
          OverlapsAllOtherSections: false,
          CheckoutPageDescription: '',
          PrivacyLevel: 'private',
        };
      }
      if (!latestVenue.Sections[options.sectionId].Images) latestVenue.Sections[options.sectionId].Images = [];

      // Append new paths to existing ones
      latestVenue.Sections[options.sectionId].Images = [
        ...(latestVenue.Sections[options.sectionId].Images || []),
        ...relativePathsToAdd
      ];
    } else {
      // Ensure PhotoUrls array exists
      if (!latestVenue.PhotoUrls) latestVenue.PhotoUrls = [];

      // Append new paths to existing ones
      latestVenue.PhotoUrls = [
        ...(latestVenue.PhotoUrls || []),
        ...relativePathsToAdd
      ];
    }

    // Write the updated venue data back to Firestore
    transaction.update(venueRef, latestVenue as Partial<Venue>);
  });
}

export async function signInWithFirebase(
  email: string,
  password: string
): Promise<void> {
  const auth = getAuth();
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
}

