import { User } from '../../../../shared/models';
import { db } from './app';
import { doc, getDoc, collection, getDocs, updateDoc, query, where } from 'firebase/firestore';


export async function updateUser(userId: string, userData: Partial<User>): Promise<void> {
  try {
    const userRef = doc(db, 'users', userId);
    if (userData.AdminOfVenueIds) {
      userData.AdminOfVenueIds = Array.from(new Set(userData.AdminOfVenueIds));
    }
    await updateDoc(userRef, userData);
    console.log('User updated successfully');
  } catch (error) {
    console.error('Error updating user:', error);
    throw error;
  }
}

export async function getUserByEmail(email: string): Promise<User | null> {
  try {
    // Normalize email - trim and lowercase
    const normalizedEmail = email.trim().toLowerCase();
    const originalEmail = email.trim(); // Preserve case but trim whitespace

    // Try with normalized email first
    let userRef = collection(db, 'users');
    let q = query(userRef, where('Email', '==', normalizedEmail));
    let querySnapshot = await getDocs(q);
    
    // If not found, try with original case-preserved email
    if (querySnapshot.empty && normalizedEmail !== originalEmail) {
      q = query(userRef, where('Email', '==', originalEmail));
      querySnapshot = await getDocs(q);
    }

    if (!querySnapshot.empty) {
      const userData = querySnapshot.docs[0].data();

      // For backwards compatibility, if AdminOfVenueId exists, convert it to AdminOfVenueIds
      let adminOfVenueIds: string[] = [];
      if (userData.AdminOfVenueId) {
        adminOfVenueIds = [userData.AdminOfVenueId];
      }
      if (userData.AdminOfVenueIds && userData.AdminOfVenueIds.length > 0) {
        adminOfVenueIds = [...adminOfVenueIds, ...userData.AdminOfVenueIds];
      }
      if (adminOfVenueIds.length > 0) {
        adminOfVenueIds = Array.from(new Set(adminOfVenueIds));
      }

      // Create user with found data
      const user: User = {
        ID: querySnapshot.docs[0].id,
        FirstName: userData.FirstName,
        LastName: userData.LastName,
        Email: userData.Email,
        Number: userData.Number,
        SignupDate: userData.SignupDate,
        Type: userData.Type,
        FromEvent: userData.FromEvent,
        AdminOfVenueIds: adminOfVenueIds,
      };

      // Optional: Update the email to normalized version if it wasn't already
      // Uncomment if you want to gradually normalize database on read
      /*
      if (userData.Email !== normalizedEmail) {
        await updateDoc(querySnapshot.docs[0].ref, {
          Email: normalizedEmail
        });
        console.log(`Updated email from ${userData.Email} to ${normalizedEmail}`);
        // Update the user object too
        user.Email = normalizedEmail;
      }
      */

      return user;
    }
    return null;
  } catch (error) {
    console.error('Error fetching user:', error);
    throw error;
  }
}

export async function getUsersByAdminVenueId(venueId: string): Promise<User[] | null> {
  try {
    const userRef = collection(db, 'users');
    const q = query(userRef, where('AdminOfVenueIds', 'array-contains', venueId));
    const querySnapshot = await getDocs(q);
    const users: User[] = [];


    querySnapshot.forEach((doc) => {
      const userData = doc.data();
      let adminOfVenueIds: string[] = [];
      if (userData.AdminOfVenueId) {
        adminOfVenueIds = [userData.AdminOfVenueId];
      }
      if (userData.AdminOfVenueIds && userData.AdminOfVenueIds.length > 0) {
        adminOfVenueIds = [...adminOfVenueIds, ...userData.AdminOfVenueIds];
      }

      if (adminOfVenueIds.length > 0) {
        adminOfVenueIds = Array.from(new Set(adminOfVenueIds));
      }
      const user: User = {
        ID: doc.id,
        FirstName: userData.FirstName,
        LastName: userData.LastName,
        Email: userData.Email,
        Number: userData.Number,
        SignupDate: userData.SignupDate,
        Type: userData.Type,
        FromEvent: userData.FromEvent,
        AdminOfVenueIds: adminOfVenueIds,
      };
      users.push(user);
    });

    return users;
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
}

export async function isAdmin(email: string): Promise<boolean> {
  try {
    const adminEmailsDoc = await getDoc(doc(db, 'private', 'admin_emails'));
    return adminEmailsDoc.exists() && adminEmailsDoc.data()[email] === true;
  } catch (error: any) {
    if (error.code === 'permission-denied') {
      return false;
    }
    console.error('Error checking admin status:', error);
    return false;
  }
}
