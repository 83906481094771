import { db, getCurrentUser } from './app';
import { collection, addDoc } from 'firebase/firestore';
import { Venue } from '../../../../shared/models';

export interface VenueCreationEvent {
    venueId: string;
    venueName: string;
    step: string;
    userId: string;
    userEmail: string;
    timestamp: Date;
}

export async function logVenueCreationEvent(event: Omit<VenueCreationEvent, 'timestamp' | 'userId' | 'userEmail'>): Promise<void> {
    try {
        const user = getCurrentUser();
        if (!user) {
            console.error('No user logged in');
            return;
        }

        const eventsRef = collection(db, 'venueCreationEvents');
        await addDoc(eventsRef, {
            ...event,
            userId: user.uid,
            userEmail: user.email,
            timestamp: new Date(),
        });
        console.log(`Logged venue creation event for step: ${event.step}, venue: ${event.venueName}`);
    } catch (error) {
        console.error('Error logging venue creation event:', error);
    }
}

export async function logErrorToFirebase(error: Error, additionalInfo: any = {}) {
    return
    // console.log(' Not Logging error to Firebase:', error);
    // if its localhost, don't log
    if (typeof window !== 'undefined' && window.location.hostname === 'localhost') {
        return;
    }
    // if the error mentions webgl, don't log
    if (error.message.includes('WebGL')) {
        return;
    }
    try {
        const cleanedData = {
            message: error.message || 'No message provided',
            stack: error.stack || 'No stack trace available',
            timestamp: new Date(),
            url: typeof window !== 'undefined' ? window.location.href : 'server-side',
            ...Object.fromEntries(
                Object.entries(additionalInfo).filter(([_, value]) => value !== undefined)
            )
        };

        await addDoc(collection(db, 'errors'), cleanedData);
    } catch (logError) {
        console.error('Failed to log error to Firebase:', logError);
    }
}