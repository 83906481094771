import { db } from './app';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';
import { EventDetails, EventGuest } from '../../../../shared/models';
import { getBackendURL } from '../util';

// Get an event by ID
export const getEventById = async (eventId: string): Promise<EventDetails | null> => {
    try {
        // Validate eventId to ensure it's not empty or invalid
        if (!eventId || typeof eventId !== 'string' || eventId.trim() === '') {
            console.error('Invalid event ID provided to getEventById');
            throw new Error('Invalid event ID: ID cannot be empty');
        }

        const eventRef = doc(db, 'events', eventId);
        const eventDoc = await getDoc(eventRef);
        if (eventDoc.exists()) {
            const eventData = eventDoc.data();

            const convertToDate = (value: any): Date => {
                if (!value) return new Date();
                if (value && typeof value === 'object' && 'toDate' in value) {
                    const date = value.toDate();
                    if (date instanceof Date) return date;
                }
                if (typeof value === 'string') {
                    const date = new Date(value);
                    if (!isNaN(date.getTime())) return date;
                }
                if (value instanceof Date && !isNaN(value.getTime())) return value;
                return new Date(); // Fallback
            };

            const event: EventDetails = {
                ID: eventDoc.id,
                VenueID: eventData.VenueID,
                EventTitle: eventData.EventTitle,
                SectionID: eventData.SectionID,
                CreatorName: eventData.CreatorName,
                CreatorEmail: eventData.CreatorEmail,
                CreatorContribution: eventData.CreatorContribution,
                CreatorEventPaymentID: eventData.CreatorEventPaymentID,
                CreatorEventGuestID: eventData.CreatorEventGuestID,
                StatusDescription: eventData.StatusDescription,
                TotalPrice: eventData.TotalPrice,
                CapacityAtTimeOfEventCreation: eventData.CapacityAtTimeOfEventCreation,
                GuestPrice: eventData.GuestPrice,
                IsAutoApproveGuests: eventData.IsAutoApproveGuests,
                IsAutoCompletePayment: eventData.IsAutoCompletePayment,
                EventDescription: eventData.EventDescription,
                EventStartTime: convertToDate(eventData.EventStartTime),
                EventEndTime: convertToDate(eventData.EventEndTime),
                ExpirationDate: convertToDate(eventData.ExpirationDate),
                Status: eventData.Status,
                UserDisplayNameToEventGuestID: eventData.UserDisplayNameToEventGuestID,
                CreatorPhone: eventData.CreatorPhone,
                PendingDisplayNameToEventGuestID: eventData.PendingDisplayNameToEventGuestID ? eventData.PendingDisplayNameToEventGuestID : {},
                AmountPaid: eventData.AmountPaid,
            };
            return event;
        }
        return null;
    } catch (error) {
        console.error('Error getting event:', error);
        throw error;
    }
};

// Verify event password
export const verifyEventPassword = async (eventId: string, password: string): Promise<boolean> => {
    try {
        const response = await fetch(getBackendURL('editEvent'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                eventId,
                password,
                action: 'verify_password'
            }),
        });

        if (!response.ok) {
            return false;
        }

        const data = await response.json();
        return response.status === 200;
    } catch (error) {
        console.error('Error verifying event password:', error);
        throw error;
    }
};



// Get guests for an event (requires password)
export const getEventGuests = async (eventId: string, password: string): Promise<EventGuest[]> => {
    try {
        const response = await fetch(getBackendURL('editEvent'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                eventId,
                password,
                action: 'read_guests'
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to fetch event guests');
        }

        const data = await response.json();
        return data.guests || [];
    } catch (error) {
        console.error('Error getting event guests:', error);
        throw error;
    }
};

// Update an event (requires password)
export const updateEvent = async (eventId: string, password: string, updateData: Partial<EventDetails>): Promise<void> => {
    try {
        const response = await fetch(getBackendURL('editEvent'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                eventId,
                password,
                updatedEventData: updateData,
                action: 'update_event'
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to update event');
        }
    } catch (error) {
        console.error('Error updating event:', error);
        throw error;
    }
};

// Update guest status (requires password)
export const updateGuestStatus = async (eventId: string, password: string, guestId: string, status: 'pending' | 'approved' | 'rejected'): Promise<void> => {
    try {
        const response = await fetch(getBackendURL('editEvent'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                eventId,
                password,
                updatedEventData: {
                    guest: {
                        ID: guestId,
                        Status: status
                    }
                },
                action: 'update_guest'
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to update guest status');
        }
    } catch (error) {
        console.error('Error updating guest status:', error);
        throw error;
    }
};

// Remove a guest from an event (requires password)
export const removeGuestFromEvent = async (eventId: string, password: string, guestId: string): Promise<void> => {
    try {
        const response = await fetch(getBackendURL('editEvent'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                eventId,
                password,
                updatedEventData: {
                    guest: {
                        ID: guestId,
                        Status: 'rejected',
                        RemovedAt: new Date().toISOString()
                    }
                },
                action: 'update_guest'
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to remove guest from event');
        }
    } catch (error) {
        console.error('Error removing guest from event:', error);
        throw error;
    }
};
